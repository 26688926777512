import { makeStyles } from '@material-ui/core/styles'

export const crStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    margin: 0,
    height: '480px',
    width: '335px',
    overflowY: 'hidden',
    overflowX: 'hidden',
    [theme.breakpoints.down(370)]: {
      width: 287,
      height: 450,
    },
  },
  image: {
    width: '100%',
    padding: '0 !important',
    margin: 0,
  },
  gridContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  eventsHolder: {
    flex: 1,
    width: '100%',
    margin: 0,
    padding: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  accordionHeaderContainer: {
    background: theme.palette.backgroundColor.ternary,
    borderRadius: theme.spacing(0.5),
  },
  eventCard: {
    width: '95%',
    borderRadius: '1rem',
    margin: '0 auto 10px auto',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  footer: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    background: theme.palette.backgroundColor.secondary,
    borderTop: `1px solid ${theme.palette.backgroundColor.main}`,
  },
  footerIcon: {
    color: theme.palette.components.icon,
    cursor: 'pointer',
    padding: '2px',
    '&:hover': {
      backgroundColor: theme.palette.button.hoverLight,
      borderRadius: '5px',
    },
  },
  iconfooter: {
    height: '40px',
  },

  // about us tab
  aboutUsTextContainer: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  aboutUsHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: theme.spacing(0.5),
    height: '30px',
    color: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.button.hoverDark,
    },
    '&:focus': {
      background: theme.palette.button.hoverDark,
    },
  },
  aboutUsNavText: { paddingLeft: theme.spacing(0.5) },
  aboutUsHeaderBackArrow: {
    cursor: 'pointer',
  },
  aboutUsImageDiv: {
    height: 156,
    width: 300,
    overflow: 'hidden',
    textAlign: 'center',
    alignContent: 'center',
    backgroundColor: theme.color.rcsaBlue,
  },
  // contact card
  contactCardsContainer: {
    flex: 1,
    overflowY: 'auto',
    marginTop: '10px',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    width: 335,
  },
  contactCard: {
    width: '100%',
    '&:last-child': {
      marginTop: '20px',
    },
  },
  contactCardContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: theme.spacing(0.5),
    '&:last-child': {
      paddingBottom: '0px',
    },
  },
  contactIconTextContainer: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.components.icon,
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.button.hoverDark,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(0, 1),
    },
    '&:focus': {
      background: theme.palette.button.hoverDark,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(0, 1),
    },
  },
  contactText: {
    '&::before': {
      whiteSpace: 'pre',
    },
    marginLeft: theme.spacing(0.5),
    color: theme.palette.components.linkText,
  },
  divider: {
    width: 300,
  },
  // events tab
  eventsHeaderContainer: {
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  eventsCalendarButtonContainer: {
    width: '50%',
  },
  eventsImageDiv: {
    width: 282,
    height: 212,
    margin: 'auto',
    backgroundColor: theme.palette.components.rcsaBlue,
  },
  // checklist tab
  accordionContainer: {
    width: '100%',
    height: '343px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  accordion: {
    width: '100%',
  },
  accordionItem: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.base.lightGray}`,
    '&:first-child': {
      borderTop: `1px solid ${theme.palette.base.lightGray}`,
    },
  },
  accordionItemText: {
    paddingTop: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  },
  accordionDetails: {
    background: theme.palette.backgroundColor.ternary,
    borderRadius: theme.spacing(0.5),
    marginTop: theme.spacing(-1),
  },
  itemBullet: {
    color: theme.palette.components.rcsaBlue,
    display: 'flex',
  },
  downArrow: {
    color: theme.palette.components.rcsaBlue,
  },
  // amenities tab
  amenitiesListContainer: {
    padding: theme.spacing(0.5),
    height: '318px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  amenitiesItemContainer: {
    padding: '0px',
    '&:last-child': {
      paddingBottom: theme.spacing(1),
    },
  },
  amenitiesTextContainer: {
    padding: `0px ${theme.spacing(0.5)}px`,
  },
  amenitiesImageDiv: {
    minHeight: 120,
    maxHeight: 180,
    overflow: 'hidden',
    alignContent: 'center',
  },
}))

export default crStyles
