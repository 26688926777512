import { makeStyles } from '@material-ui/core/styles'

export const ManageNewsMobileStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    width: '100%',
    overscrollBehavior: 'contain',
    justifyContent: 'center',
  },
  appBar: {
    color: 'rgba(0,0,0,0.54)',
  },
  mobileBackButtonIcon: {
    fontSize: 24,
    color: theme.palette.base.white,
  },
  pageTitle: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 1,
    wordBreak: 'keep-all',
    overflow: 'hidden',
    fontSize: 22,
    fontWeight: '400',
    textOverflow: 'ellipsis',
    color: theme.palette.base.white,
  },
  body: {
    paddingTop: 55,
  },
  footerButton: {
    height: '48px',
    width: '100%',
    backgroundColor: theme.palette.paper.primary,
    position: 'fixed',
    bottom: 0,
    padding: 8,
    borderTop: `1px solid ${theme.palette.base.lightGray}`,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  helperText: {
    marginRight: theme.spacing(1),
  },
}))
