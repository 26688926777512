import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) => ({
  container: {
    padding: 0,
    margin: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  gridContainer: {
    backgroundColor: theme.palette.paper.secondary,
  },
  courseGrid: {
    width: 317,
  },
  singleCourse: {
    width: '100%',
    padding: theme.spacing(1),
    margin: theme.spacing(0.5),
    backgroundColor: theme.palette.paper.primary,
    borderRadius: theme.spacing(1),
    boxShadow: '0 0 3px rgba(0,0,0,0.3)',
  },
  sectionGrid: {
    marginRight: theme.spacing(4),
  },
  singleLine: {
    marginBottom: theme.spacing(0.5),
  },
  link: {
    color: theme.palette.components.linkText,
    padding: 0,
    marginTop: -1,
    fontSize: '0.9rem',
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:focus-visible': {
      outline: `1px solid ${theme.palette.button.focusBorder}`,
      boxShadow: `0 0 0 3px ${theme.palette.button.focusOutline}`,
    },
  },
}))

export default styles
