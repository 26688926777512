import * as React from 'react'
import { List, ListItem, Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc'
import ReorderFavoriteAppsListStyles from './ReorderFavoriteAppsListStyles'

import {
  selectIsGuest,
  selectReorderFavoriteApps,
  updateFavoriteAppsList,
} from '../../appSlice'
import App from '../App/App'

const SortableApp = SortableElement(
  ({
    app,
    isGuest,
    isFavoritedList,
    hideInfoIcon,
    enableClick,
    reorderMode,
  }) => {
    const classes = ReorderFavoriteAppsListStyles()
    return (
      <ListItem
        classes={{ root: classes.padding }}
        key={app.title}
        disableGutters
      >
        <App
          hideInfoIcon={hideInfoIcon}
          enableClick={enableClick}
          isMobile
          app={app}
          isFavoritedList={isFavoritedList}
          isGuest={isGuest}
          reorderMode={reorderMode}
        />
      </ListItem>
    )
  },
)

const SortableListMobile = SortableContainer(
  ({
    myFavoriteAppsList,
    isGuest,
    isFavoritedList,
    hideInfoIcon,
    enableClick,
    reorderMode,
  }) => (
    <List>
      {myFavoriteAppsList?.map((app, index) => (
        <SortableApp
          hideInfoIcon={hideInfoIcon}
          enableClick={enableClick}
          key={`item-${app.title}`}
          index={index}
          app={app}
          isGuest={isGuest}
          isFavoritedList={isFavoritedList}
          reorderMode={reorderMode}
        />
      ))}
    </List>
  ),
)

const SortableAppDesktop = SortableElement(
  ({ app, isGuest, gridWidth, isFavoritedList, reorderMode }) => (
    <Grid item xs={gridWidth}>
      <App
        key={app.name}
        app={app}
        isGuest={isGuest}
        isFavoritedList={isFavoritedList}
        reorderMode={reorderMode}
      />
    </Grid>
  ),
)

const SortableListDesktop = SortableContainer(
  ({
    myFavoriteAppsList,
    isGuest,
    dimensions,
    isFavoritedList,
    reorderMode,
  }) => (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ marginTop: 30 }}
    >
      {myFavoriteAppsList?.map((app, index) => (
        <SortableAppDesktop
          key={`item-${app.title}`}
          index={index}
          app={app}
          isGuest={isGuest}
          gridWidth={dimensions.gridWidth}
          isFavoritedList={isFavoritedList}
          reorderMode={reorderMode}
        />
      ))}
    </Grid>
  ),
)

const ReorderAppList = ({
  isMobile,
  favoritesAppList,
  dimensions,
  hideInfoIcon,
  enableClick,
}) => {
  const dispatch = useDispatch()
  const [appList, setAppList] = React.useState(favoritesAppList)
  const isGuest = useSelector(selectIsGuest)
  const isFavoritedList = useSelector(selectReorderFavoriteApps) // Redux value which receives a signal from AppTabs
  const { reorderFavoriteApps } = useSelector((s) => s.myApps)

  React.useEffect(() => {
    setAppList(favoritesAppList)
  }, [])

  React.useEffect(() => {
    setAppList(favoritesAppList)
  }, [favoritesAppList])

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const shiftedIndexList = arrayMove(appList, oldIndex, newIndex)
    setAppList(shiftedIndexList)
    const favoritesAppListIndex = shiftedIndexList?.map((app) =>
      parseInt(app.id, 10),
    )
    dispatch(updateFavoriteAppsList(favoritesAppListIndex))
  }
  return (
    <>
      {isMobile ? (
        <div style={{ paddingBottom: 50 }}>
          <SortableListMobile
            hideInfoIcon={hideInfoIcon}
            myFavoriteAppsList={appList}
            onSortEnd={onSortEnd}
            // useDragHandle
            isGuest={isGuest}
            isFavoritedList={isFavoritedList}
            enableClick={enableClick}
            reorderMode={reorderFavoriteApps}
          />
        </div>
      ) : (
        <SortableListDesktop
          axis="xy"
          onSortEnd={onSortEnd}
          myFavoriteAppsList={appList}
          isGuest={isGuest}
          dimensions={dimensions}
          isFavoritedList={isFavoritedList}
          reorderMode={reorderFavoriteApps}
        />
      )}
    </>
  )
}

export default ReorderAppList
