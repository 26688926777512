/* eslint-disable no-unused-vars */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, Grid, ButtonBase } from '@material-ui/core'

import Loading from '../helpers/Loading'
import Widget from '../Widget'

import styles from './MyTeachingScheduleStyles'
import {
  fetchTeachingSchedule,
  selectTeachingScheduleData,
  selectTeachingScheduleStatus,
} from './MyTeachingScheduleSlice'
import { logDashboardEvent } from '../../events'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'

function MyTeachingSchedule({
  className,
  title,
  widgetId,
  showNote,
  noteMessage,
  notePosition,
  noteBackground,
  noteIcon,
  noteUrl,
  noteFontColor,
}) {
  const classes = styles()
  const dispatch = useDispatch()
  const widgetData = useSelector(selectTeachingScheduleData)
  const widgetStatus = useSelector(selectTeachingScheduleStatus)

  React.useEffect(() => {
    dispatch(fetchTeachingSchedule())
  }, [])

  return (
    <Widget className={className}>
      <Widget.Header title={title} category="default" />
      <Widget.Content
        className={classes.container}
        showNote={showNote}
        noteMessage={noteMessage}
        notePosition={notePosition}
        noteBackground={noteBackground}
        noteIcon={noteIcon}
        noteUrl={noteUrl}
        noteFontColor={noteFontColor}
      >
        {renderWidgetState(
          widgetStatus,
          widgetData,
          classes,
          widgetId,
          dispatch,
        )}
      </Widget.Content>
    </Widget>
  )
}

function renderWidgetState(
  widgetStatus,
  widgetData,
  classes,
  widgetId,
  dispatch,
) {
  switch (widgetStatus) {
    case 'fulfilled':
      return (
        <Loaded
          widgetData={widgetData}
          classes={classes}
          widgetId={widgetId}
          dispatch={dispatch}
        />
      )
    case 'pending':
      return <Loading />
    case 'error':
      return <ErrorMessage />
    default:
      return <ErrorMessage />
  }
}

function Loaded({ widgetData, classes, widgetId, dispatch }) {
  const { tab } = useSelector((s) => s.myTeachingSchedule)
  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      className={classes.gridContainer}
    >
      {widgetData[tab]?.map((course) => (
        <Grid
          item
          key={`${course.courseTitle} ${course.meetingDay} ${course.startTime} ${course.course}`}
          className={classes.courseGrid}
        >
          <CourseInfo
            course={course}
            classes={classes}
            widgetId={widgetId}
            dispatch={dispatch}
          />
        </Grid>
      ))}
    </Grid>
  )
}

function CourseInfo({ course, classes, widgetId, dispatch }) {
  const handleLinkClick = (link, buttonTitle) => {
    window.open(link, '_blank')
    if (widgetId && dispatch) {
      logDashboardEvent(dispatch, 'dashboardEvent', widgetId, buttonTitle, link)
    }
  }

  return (
    <Grid container className={classes.singleCourse} direction="column">
      <Grid item>
        <Typography variant="subtitle1">{course.courseTitle}</Typography>
      </Grid>
      <Grid item container direction="row" justifyContent="space-between">
        <Grid item className={classes.singleLine}>
          <Typography variant="body2">
            <b>Course #:</b> {course.course}
          </Typography>
        </Grid>
        <Grid item className={classes.sectionGrid}>
          <Typography variant="body2">
            <b>Section:</b> {course.sectionNo}
          </Typography>
        </Grid>
      </Grid>
      <Grid item className={classes.singleLine}>
        <Typography variant="body2">
          <b>Enrollment:</b> {course.roster ? course.roster : 'n/a'}
        </Typography>
      </Grid>
      <Grid item className={classes.singleLine}>
        <Typography variant="body2">
          <b>Max Enrollment:</b>{' '}
          {course.maxEnrollment
            ? course.maxEnrollment.replace(/^0+/, '')
            : 'n/a'}
        </Typography>
      </Grid>
      <Grid item className={classes.singleLine}>
        <Typography variant="body2">
          <b>Registration Stop Point:</b>{' '}
          {course.registrationStopPoint
            ? course.registrationStopPoint.replace(/^0+/, '')
            : 'n/a'}
        </Typography>
      </Grid>
      <Grid item className={classes.singleLine}>
        <Typography variant="body2">
          <b>Time:</b> {course.meetingDay} {course.startTime} - {course.endTime}
        </Typography>
      </Grid>
      <Grid item className={classes.singleLine}>
        <Typography variant="body2">
          <b>Type:</b> {course.courseType ? course.courseType : 'n/a'}
        </Typography>
      </Grid>
      <Grid item className={classes.singleLine}>
        <Typography variant="body2">
          <b>Location:</b>{' '}
          {course.bldgInfo &&
          course.bldgInfo.bldgVM &&
          course.bldgInfo.bldgVM.bldgDescr ? (
            <>
              {course.bldgInfo.bldgURL ? (
                <>
                  <ButtonBase
                    className={classes.link}
                    onClick={() =>
                      handleLinkClick(course.bldgInfo.bldgURL, 'RutgersMap')
                    }
                  >
                    {course.bldgInfo.bldgVM.bldgDescr}
                  </ButtonBase>{' '}
                  (
                  {course.dcsUrl ? (
                    <ButtonBase
                      className={classes.link}
                      onClick={() => handleLinkClick(course.dcsUrl, 'DCS')}
                    >
                      {course.bldgInfo.bldgCd}-{course.roomno}
                    </ButtonBase>
                  ) : (
                    `${course.bldgInfo.bldgCd}-${course.roomno}`
                  )}
                  )
                </>
              ) : (
                <span>
                  {course.bldgInfo.bldgVM.bldgDescr} ({course.bldgInfo.bldgCd}-
                  {course.roomno})
                </span>
              )}
            </>
          ) : (
            'No location info'
          )}
        </Typography>
      </Grid>
      <Grid item className={classes.singleLine}>
        <Typography variant="body2">
          <b>Campus:</b>{' '}
          {course.campusName ? course.campusName : course.campusCode}
        </Typography>
      </Grid>
      <Grid item className={classes.singleLine}>
        {course.examCd && (
          <Typography variant="body2">
            <b>Final:</b> {course.examCd} - {course.examDay}
            {course.examTime && ` - ${course.examTime}`}
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

export default MyTeachingSchedule
