import React from 'react'
import clsx from 'clsx'
import { Box, Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import useNewsStyles from './NewsStyles'
import CheckIsMobile from '../../../utils/isMobileHook'
import NewsSection from './Pages/NewsSection/NewsSection'
import Loading from '../dashboard/widgets/helpers/Loading'
import {
  fetchNews,
  fetchSocialMediaLocationForArticles,
  fetchSources,
  updateRestArticlesIndex,
} from './newsSlice'
import NewsSearchBar from './components/NewsSearchBar/NewsSearchBar'
import SearchResultList from './components/SearchResultList/SearchResultList'
import NewsSearchBarMobile from './components/NewsSearchBar/NewsSearchBarMobile'
import ErrorPage from './ErrorPage'

function News() {
  const classes = useNewsStyles()
  const dispatch = useDispatch()
  const { newsStatus } = useSelector((s) => s.news)

  const appsPageRef = React.useRef()
  const [y, setY] = React.useState(window.scrollY)

  const handleNavigation = React.useCallback(
    (e) => {
      const window = e.currentTarget
      if (y < window.scrollY) {
        // call function to add more items to the More News Section
        if (
          appsPageRef.current.getBoundingClientRect().bottom <=
          window.innerHeight
        ) {
          dispatch(updateRestArticlesIndex())
        }
      }
      setY(window.scrollY)
    },
    [y],
  )

  React.useEffect(() => {
    setY(window.scrollY)
    window.addEventListener('scroll', handleNavigation)

    return () => {
      window.removeEventListener('scroll', handleNavigation)
    }
  }, [handleNavigation])

  React.useEffect(() => {
    dispatch(fetchSocialMediaLocationForArticles()).then(() => {
      dispatch(fetchSources()).then(() => {
        dispatch(fetchNews())
      })
    })
  }, [])
  return (
    <Box className={classes.centerPage}>
      {renderNewsState(classes, newsStatus, appsPageRef)}
    </Box>
  )
}

function renderNewsState(classes, newsStatus, appsPageRef) {
  switch (newsStatus) {
    case 'fulfilled':
      return <Loaded classes={classes} appsPageRef={appsPageRef} />
    case 'pending':
      return <Loading />
    case 'error':
      return <ErrorPage />
    default:
      return <Box />
  }
}

function Loaded({ classes, appsPageRef }) {
  const isMobile = CheckIsMobile()
  const { drawerIsOpen } = useSelector((state) => state.navigation)
  const {
    news,
    displaySearchBar,
    displaySearchItems,
    unsubscribedSources,
    search,
  } = useSelector((state) => state.news)

  const [sortedNews, setSortedNews] = React.useState({})

  React.useEffect(() => {
    if (news) {
      sortAndFilterNews()
    }
  }, [news, unsubscribedSources])

  const sortAndFilterNews = async () => {
    const sortedNewsObj = Object.entries(news)
      .sort(([, a], [, b]) => a.order - b.order)
      .map(([key, value]) => {
        const filteredArticles = value.articles.filter(
          (article) => !unsubscribedSources.includes(article.sourceName),
        )
        const hidden = filteredArticles.length === 0
        return {
          key,
          ...value,
          hidden,
          articles: filteredArticles,
        }
      })
    setSortedNews(sortedNewsObj)
  }

  return (
    <Box
      className={clsx(
        drawerIsOpen ? classes.sideNavOpen : null,
        classes.contentContainer,
      )}
      ref={appsPageRef}
    >
      {isMobile && displaySearchBar && (
        <Grid item xs={12} className={classes.searchBar}>
          <NewsSearchBar isMobile={isMobile} />
        </Grid>
      )}
      {displaySearchItems ? <NewsSearchBarMobile /> : null}

      {search.enableSearch && search.searchQuery !== '' ? (
        <SearchResultList classes={classes} />
      ) : (
        <>
          {Object.keys(sortedNews).length !== 0 &&
            sortedNews.map((newsSection, index) => (
              <NewsSection
                isMobile={isMobile}
                key={newsSection.section}
                newsSection={newsSection}
                loadingBuffer={index * 100}
              />
            ))}
        </>
      )}
    </Box>
  )
}

export default News
