/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { UAParser } from 'ua-parser-js'
import axios from 'axios'

const parser = new UAParser()

export const fetchMyProfile = createAsyncThunk('fetchMyProfile', async () => {
  // Comment out this if statement if you want to debug your actual profile call in dev
  const jwtToken = localStorage.getItem('myrJwtToken')
  const rcpid = localStorage.getItem('rcpid')
  return axios.all([
    axios.get(process.env.REACT_APP_PROFILE_ENDPOINT, {
      headers: {
        JWT: jwtToken,
        XWT: rcpid,
      },
    }),
    axios.get(process.env.REACT_APP_LOGIN_USER_PROFILE, {
      headers: {
        JWT: jwtToken,
        XWT: rcpid,
      },
    }),
  ])
})

export const fetchProxyProfile = createAsyncThunk(
  'fetchProxyProfile',
  async (proxyNetId) => {
    const jwtToken = localStorage.getItem('myrJwtToken')
    const rcpid = localStorage.getItem('rcpid')
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'production') {
      return axios.all([
        axios.get(
          `${process.env.REACT_APP_PROXY_USER_PROFILE}/?proxyToNetid=${proxyNetId}`,
          {
            headers: {
              JWT: jwtToken,
              XWT: rcpid,
            },
          },
        ),
      ])
    }
    return axios.all([
      axios.get(`${process.env.REACT_APP_PROXY_USER_PROFILE}`, {
        headers: {
          JWT: jwtToken,
          XWT: rcpid,
        },
      }),
    ])
  },
)

export const fetchProxyFSProfile = createAsyncThunk(
  'fetchProxyFSProfile',
  async () => {
    const jwtToken = localStorage.getItem('myrJwtToken')
    const res = axios.get(process.env.REACT_APP_NG_SIGNIN, {
      headers: {
        JWT: jwtToken,
      },
    })
    return res.data
  },
)

export const fetchProfileLogEvent = createAsyncThunk(
  'fetchProfileLogEvent',
  async ({
    module,
    object,
    objectId,
    action,
    actionContext,
    actionContextValue,
    rcpid,
    url,
    timestamp,
    info,
  }) => {
    const jwtToken = localStorage.getItem('myrJwtToken')
    const res = axios.post(
      `${process.env.REACT_APP_LOG_SINGLE_EVENT}`,
      {
        module,
        object,
        objectId,
        action,
        actionContext,
        actionContextValue,
        rcpid,
        url,
        timestamp,
        info,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          JWT: jwtToken,
          XWT: rcpid,
        },
      },
    )
    return res.data
  },
)

export const fetchProfileLogEvents = createAsyncThunk(
  'fetchProfileLogEvents',
  async (_, { getState }) => {
    const state = getState()
    const { userEvents } = state.myProfile

    const jwtToken = localStorage.getItem('myrJwtToken')
    const rcpid = localStorage.getItem('rcpid')
    const res = axios.post(process.env.REACT_APP_LOG_BATCH_EVENTS, userEvents, {
      headers: {
        'Content-Type': 'application/json',
        JWT: jwtToken,
        XWT: rcpid,
      },
    })
    return res.data
  },
)

export const createCommunityIDUser = createAsyncThunk(
  'createCommunityIDUser',
  async () => {
    const jwtToken = localStorage.getItem('myrJwtToken')
    const rcpid = localStorage.getItem('rcpid')
    const res = axios.post(
      process.env.REACT_APP_ADDCOMMUNITYID_USER,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          JWT: jwtToken,
          XWT: rcpid,
        },
      },
    )
    return res.data
  },
)

const initialState = {
  title: 'myProfile',
  status: 'idle',
  data: [],
  userProfile: {},
  proxyProfile: {},
  proxyFSProfileState: 'idle',
  tabValue: 0,
  dialogOpen: false,
  userEvent: {},
  userEvents: [],
  fetchProfileLogEventStatus: 'idle',
  fetchProfileLogEventsStatus: 'idle',
  createCommunityIDUserStatus: 'idle',
}

const myProfileSlice = createSlice({
  name: 'profileWidget',
  initialState,
  reducers: {
    updateTabValue: (state, action) => {
      state.tabValue = action.payload
    },
    updateDialog: (state) => {
      state.dialogOpen = !state.dialogOpen
    },
    handleProxyLogout: () => {
      // remove previous claims
      localStorage.removeItem('user')
      localStorage.removeItem('rutgersEduRCPID')
      localStorage.removeItem('myrJwtToken')
      localStorage.removeItem('myrSwtToken')
      localStorage.removeItem('myrFirebaseToken')
      localStorage.removeItem('proxyNetId')
      localStorage.removeItem('proxyRcpId')
      localStorage.removeItem('proxyPermissions')
      localStorage.removeItem('codeResponse')
      window.location.href = process.env.REACT_APP_LOGIN_CAS_SHELL
    },
    addProfileLogEvent: (state, action) => {
      if (action.payload.module) {
        state.userEvents = [
          ...state.userEvents,
          { ...action.payload, info: JSON.stringify(parser.getResult()) },
        ]
      }
    },
    resetProfileLogEvents: (state) => {
      state.userEvents = []
    },
  },
  extraReducers: {
    [fetchMyProfile.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchMyProfile.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchMyProfile.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.data = action.payload[0]?.data
      state.userProfile = action.payload[1]?.data
    },
    [fetchProxyProfile.rejected]: (state) => {
      state.proxyProfileStatus = 'error'
    },
    [fetchProxyProfile.pending]: (state) => {
      state.proxyProfileStatus = 'pending'
    },
    [fetchProxyProfile.fulfilled]: (state, action) => {
      state.proxyProfile.rcpId = action.payload[0]?.data.rcpId
      state.proxyProfile.proxyRcpId = action.payload[0].data.proxyRcpId
      state.proxyProfile.proxyNetId = action.payload[0].data.proxyNetId
      state.proxyProfile.proxyPermissions =
        action.payload[0].data.proxyPermissions
      // remove previous claims
      localStorage.removeItem('user')
      localStorage.removeItem('rutgersEduRCPID')
      localStorage.removeItem('myrJwtToken')
      localStorage.removeItem('myrSwtToken')
      localStorage.removeItem('myrFirebaseToken')
      localStorage.removeItem('codeResponse')
      // add new claims
      localStorage.setItem('rcpid', action.payload[0].data.rcpId)
      localStorage.setItem('user', action.payload[0].data.rcpId)
      localStorage.setItem('rutgersEduRCPID', action.payload[0].data.rcpId)
      localStorage.setItem('myrJwtToken', action.payload[0].data.jwtToken)
      localStorage.setItem('proxyNetId', action.payload[0].data.proxyNetId)
      localStorage.setItem('proxyRcpId', action.payload[0].data.proxyRcpId)
      localStorage.setItem(
        'proxyPermissions',
        action.payload[0].data.proxyPermissions,
      )
    },
  },
  [fetchProxyFSProfile.pending]: (state) => {
    state.proxyFSProfileState = 'pending'
  },
  [fetchProxyFSProfile.rejected]: (state) => {
    state.proxyFSProfileState = 'error'
  },
  [fetchProxyFSProfile.fulfilled]: (state) => {
    state.proxyFSProfileState = 'fulfilled'
  },
  [fetchProfileLogEvent.pending]: (state) => {
    state.fetchProfileLogEventStatus = 'pending'
  },
  [fetchProfileLogEvent.rejected]: (state, action) => {
    state.fetchProfileLogEventStatus = 'error'
    console.log('error sending log event ', action.payload)
  },
  [fetchProfileLogEvent.fulfilled]: (state) => {
    state.fetchProfileLogEventStatus = 'fulfilled'
  },
  [fetchProfileLogEvents.pending]: (state) => {
    state.fetchProfileLogEventsStatus = 'pending'
  },
  [fetchProfileLogEvents.rejected]: (state) => {
    state.fetchProfileLogEventsStatus = 'error'
  },
  [fetchProfileLogEvents.fulfilled]: (state) => {
    state.fetchProfileLogEventsStatus = 'fulfilled'
  },
  [createCommunityIDUser.pending]: (state) => {
    state.createCommunityIDUserStatus = 'pending'
  },
  [createCommunityIDUser.rejected]: (state) => {
    state.createCommunityIDUserStatus = 'error'
  },
  [createCommunityIDUser.fulfilled]: (state) => {
    state.createCommunityIDUserStatus = 'fulfilled'
  },
})

export const {
  updateTabValue,
  updateDialog,
  handleProxyLogout,
  addProfileLogEvent,
  resetProfileLogEvents,
} = myProfileSlice.actions
export const selectMyProfileStatus = (state) => state.myProfile.status
export const selectMyProfileData = (state) => state.myProfile.data

export default myProfileSlice.reducer
