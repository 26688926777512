import Main from './pages/Main'
import Feedback from './pages/Feedback'
import AppInfo from './pages/AppInfo'
import DashboardSettings from './pages/DashboardSettings'
import Profile from './pages/Profile'
import DeviceManagement from './pages/DeviceManagment'
import Credits from './pages/Credits'
import AppSupport from './pages/AppSupport'
import Copyrights from './pages/Copyrights'
import PrivacyNotice from './pages/PrivacyNotice'
import emergencyInformation from './pages/EmergencyInformation'

const navigation = [
  {
    path: '',
    title: 'Main',
    component: Main,
  },
  {
    path: '/feedback',
    title: 'Feedback',
    component: Feedback,
  },
  {
    path: '/appinfo',
    title: 'App Info',
    component: AppInfo,
  },
  {
    path: '/dashboard',
    title: 'Dashboard Settings',
    component: DashboardSettings,
  },
  {
    path: '/profile',
    title: 'User Profile',
    component: Profile,
  },
  {
    path: '/deviceManagement',
    title: 'Device Management',
    component: DeviceManagement,
  },
  {
    path: '/credits',
    title: 'Credits',
    component: Credits,
  },
  {
    path: '/appSupport',
    title: 'App Support',
    component: AppSupport,
  },
  {
    path: '/appinfo/copyrights',
    title: 'Copyrights',
    component: Copyrights,
  },
  {
    path: '/appinfo/privacyNotices',
    title: 'Privacy Notices',
    component: PrivacyNotice,
  },
  {
    path: '/emergencyInformation',
    title: 'Emergency Information',
    component: emergencyInformation,
  },
]

export default navigation
