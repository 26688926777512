/* eslint-disable */
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import useBigArticleStyles from './BigArticleStyles'
import { addProfileLogEvent } from '../../../dashboard/widgets/MyProfile/myProfileSlice'

function BigArticle({
  article,
  isMobile = true,
  isLoading = true,
  articleSize,
}) {
  const dispatch = useDispatch()
  const classes = useBigArticleStyles({ articleSize })
  const history = useHistory()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const handleImageError = (e) => {
    let errorImage = article.sourceName.toLowerCase().includes('facebook')
      ? './assets/images/facebook-logo-primary.png'
      : 'https://placehold.co/600x400'
    e.target.src = errorImage
    e.target.style.objectFit = 'contain'
  }
  return (
    <Box className={{ marginLeft: 4, marginRight: 4 }}>
      <Card className={classes.card} elevation={0} variant="outlined">
        <CardActionArea
          onClick={() => {
            if (process.env.REACT_APP_FIREBASE_ON === 'true') {
              dispatch(
                addProfileLogEvent({
                  module: 'News',
                  object: 'Articles',
                  objectId: article.id,
                  action: 'Click',
                  actionContext: 'ViewArticle',
                  actionContextValue: '',
                  rcpid: localStorage.getItem('rutgersEduRCPID'),
                  timestamp: Date.now(),
                }),
              )
            }
            history.push({
              pathname: './ViewArticlePage',
              search: `?newsID=${article.id}`,
              state: article,
            })
          }}
        >
          <Box
            className={classes.imageContainer}
            sx={{
              padding:
                article.firebaseImageUrl || article.imageUrl
                  ? '15px 5px'
                  : '0px 0',
              height:
                article.firebaseImageUrl || article.imageUrl ? '320px' : '0px',
            }}
          >
            <Box className={classes.subImageContainer}>
              {(article.firebaseImageUrl || article.imageUrl) &&
                (isLoading ? (
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={isSmallScreen ? 180 : 300}
                  />
                ) : (
                  <CardMedia
                    component="img"
                    className={classes.articleImage}
                    image={
                      article.firebaseImageUrl
                        ? article.firebaseImageUrl
                        : article.imageUrl
                    }
                    onError={handleImageError}
                  />
                ))}
            </Box>
          </Box>
          <Grid
            container
            className={classes.cardContent}
            direction="column"
            justifyContent="space-between"
          >
            <Grid item>
              <Button
                className={classes.sponsorLink}
                component={Link}
                to={{
                  pathname: './NewsSourcePage',
                  search: `?id=${article.sourceID}&source=${article.sourceName}`,
                }}
                onClick={(e) => {
                  // This prevent default is necessary or you'll trigger the
                  // cardactionarea's link as well.
                  e.stopPropagation()
                }}
              >
                <Typography className={classes.articleSourceText}>
                  {isLoading ? <Skeleton width="100px" /> : article.sourceName}
                </Typography>
              </Button>
            </Grid>
            <Grid item>
              <Typography className={classes.articleTitle}>
                {isLoading ? <Skeleton width="250px" /> : article.id}
              </Typography>
            </Grid>
            <Grid item>
              <Grid
                container
                className={classes.bottomContainer}
                alignItems="flex-end"
                justifyContent="space-between"
              >
                {(() => {
                  // This IIFE decides what to display based on whether the page is loading or on a mobile device.
                  if (isLoading) {
                    return <Skeleton width={150} style={{ marginLeft: 9 }} />
                  }
                  if (isMobile) {
                    return (
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          component={Link}
                          to={{
                            pathname: './ViewArticlePage',
                            search: `?newsID=${article.id}`,
                            state: article,
                          }}
                          onClick={(e) => {
                            e.preventDefault() // Prevent default to stop cardactionarea's link
                          }}
                        >
                          <Typography className={classes.buttonText}>
                            Learn More
                          </Typography>
                        </Button>
                      </Grid>
                    )
                  }
                  return <Grid item></Grid>
                })()}

                <Grid item>
                  <Typography className={classes.timeElapsedText}>
                    {isLoading ? <Skeleton width={100} /> : article.published}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
    </Box>
  )
}

export default BigArticle
